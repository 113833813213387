<template>
    <div class="contactInfo_body">
        <div class="contactInfo_content">
            <img :src="agentList.wechat_image || ''" alt="" v-if="type">
            <p class="inviteText"><span v-if="!type">邀请码：</span>{{agentList.wechat}}</p>
            <p class="inviteBtn" @click="copyFun(agentList.wechat)">{{!type?'一键复制邀请码':'一键复制微信号'}}</p>
        </div>
    </div>
</template>
<script>
    import api from "../../http/api";
    import { Message } from 'element-ui';
    import { copyMethod } from "../../utils";
    import { mapState } from "vuex";
    export default {
        props: ['type','contact'],
        data() {
            return {
                agentList: {
                    wechat_image:'',
                    wechat:''
                },//代理商信息
            }
        },
        components: {

        },
        computed: {
            ...mapState(['currentAgentSet']), //
        },
        created(){
            this.agentList = {wechat_image:'',wechat:''}
            if(this.type == 'myAgent'){
                this.getMyAgent() 
            }
            else if(this.type=='inviteAgent') {
                this.agentList = this.contact
            } else {
                this.agentList= {
                    wechat:this.currentAgentSet.invite_code
                }
            }
        },
        activated() {
            //console.log('type111--->', this.type,this.currentAgentSet)
            // this.type == 'myAgent' ? this.getMyAgent() : (this.agentList={
            //     wechat:this.currentAgentSet.invite_code
            // })
        },
        methods: {
            copyFun(text) {
                copyMethod(text)
            },
            //邀请码持有者联系方式
            getMyAgent() {
                let that = this;
                api.getMyAgentCode().then(res => {
                    res.response && (that.agentList = res.response)

                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .contactInfo_body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .contactInfo_body img {
        width: 200px;
        height: 200px;
        background: #efefef;
    }

    .contactInfo_content .inviteText {
        text-align: center;
        margin: 10px auto;
    }

    .contactInfo_content .inviteBtn {
        text-align: center;
        width: 120px;
        /* margin-top:10px; */
        background: #0B41D7;
        line-height: 35px;
        margin: auto;
        color: #fff;
    }
</style>